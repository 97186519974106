import React from 'react';
import './App.css';
import Checkout from './pages/checkout';
import Invoicer from './pages/invoicer';
import Home from './pages/home';
import { BrowserRouter as Router, Route } from 'react-router-dom';

function App() {
	return (
		<Router>
			{/* <Route exact path='/checkout/:invoiceID' component={Checkout} />
			<Route exact path='/invoicer' component={Invoicer} /> */}
			<Route exact path='/' component={Home} />
		</Router>
	);
}

export default App;
